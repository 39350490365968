import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import PageWrapper from '../components/pageWrapper'
import DonationButton from '../components/donationButton'
import { Row, Col } from 'react-bootstrap'
import { renderRichText } from '../lib/richTextRender'

class Contact extends React.Component {
  render() {
    const { site, allContentfulDonationPage } = this.props.data

    const siteTitle = site.siteMetadata.title
    const [donation] = allContentfulDonationPage.edges

    return (
      <Layout>
        <PageWrapper>
          <Helmet title={siteTitle} />
          <div className="wrapper p-3">
            <div className="d-flex align-items-end mb-4">
              <div>
                <h2 className="font-weight-bold">{donation.node.title}</h2>
                <p className="font-weight-bold">{donation.node.intro}</p>
              </div>
              <div className="vl"></div>
              <DonationButton />
            </div>
            <div>{renderRichText(donation.node.overview.json)}</div>
            <h2 className="font-weight-bold">{donation.node.subtitle}</h2>
            <br />
            <br />
            <Row className="row">
              <Col md={6}>
                <div className="flex-1">
                  {renderRichText(donation.node.bankTransfer.json)}
                </div>
              </Col>
              <Col md={6}>
                <div>{renderRichText(donation.node.paypal.json)}</div>
              </Col>
            </Row>
          </div>
        </PageWrapper>
      </Layout>
    )
  }
}

export default Contact

export const pageQuery = graphql`
  query SpendenPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulDonationPage {
      edges {
        node {
          intro
          title
          subtitle
          overview {
            json
          }
          paypal {
            json
          }

          bankTransfer {
            json
          }
        }
      }
    }
  }
`
